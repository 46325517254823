import React from "react";

import { Stack } from "@envato/eds";
import { useClient } from "@envato/sso-common";

import { Container, Title } from "../../../../components/SidePanel";
import BrandingBox from "../../../../components/BrandingBox";

import { SidePanelProps } from "./index";

const DefaultSidebar: React.FC<SidePanelProps> = () => {
  const client = useClient();

  return (
    <Container>
      <Stack spacing={["2x", "6x"]}>
        {client ? (
          <Title
            text="signIn.form.title.withClient"
            values={{ name: client.name }}
            testId="signInTitle"
          />
        ) : (
          <Title text="signIn.form.title.default" testId="signInTitle" />
        )}
        <BrandingBox />
      </Stack>
    </Container>
  );
};

export default DefaultSidebar;
