import React, { useState, useCallback } from "react";

import {
  signIn,
  signOut,
  SsoError,
  SignInFailureContext,
  VerificationRequiredContext,
  samlInit,
} from "@envato/sso-js";
import {
  reloadLocation,
  setLocationHref,
  useRecaptchaV2,
  useConfig,
  useLocalisedPath,
  useLanguageCode,
  useClientParams,
  SignInFormValues as FormValues,
} from "@envato/sso-common";

import SignInForm from "./SignInForm";
import AlreadySignedIn from "./AlreadySignedIn";

export { default as routeMetaData } from "./routeMetaData";

const Route: React.FC = () => {
  const [errorMessages, setErrorMessages] = useState<string[] | undefined>(
    undefined,
  );
  const [showTwoFactor, setShowTwoFactor] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);

  const [{ recaptchaV2SiteKey, currentUser }] = useConfig();
  const languageCode = useLanguageCode();
  const params = useClientParams();
  const captcha = useRecaptchaV2();

  const continueLink = useLocalisedPath("signIn", { auto: "true" });
  const signUpLink = useLocalisedPath("signUp");
  const forgotUsernameLink = useLocalisedPath("recoverUsernames");
  const forgotPasswordLink = useLocalisedPath("requestPasswordReset");
  const passwordResetRequiredLink = useLocalisedPath("passwordResetRequired");
  const confirmLink = useLocalisedPath("confirm");
  const signInVerificationLink = useLocalisedPath("signInVerification");

  const handleSubmit = useCallback(
    async (values: FormValues): Promise<void> => {
      try {
        if (values.samlRequired) {
          const { redirect } = await samlInit({
            login: values.username,
            to: params.to,
            state: params.state,
            languageCode: values.languageCode,
          });
          setLocationHref(redirect, true);
        } else {
          const { redirect } = await signIn(values);
          setLocationHref(redirect, true);
        }
      } catch (e) {
        if (e instanceof SsoError) {
          switch (e.type) {
            case "SignInFailure": {
              const context = e.context as SignInFailureContext;
              setErrorMessages([context.errorMessage!].filter(Boolean));
              setShowTwoFactor(context.twoFactor);
              setShowCaptcha(context.captcha);
              if (context.captcha) {
                captcha.reset();
              }
              break;
            }
            case "PasswordResetRequired":
              setLocationHref(passwordResetRequiredLink, true);
              break;
            case "VerificationRequired":
              setLocationHref(
                signInVerificationLink.replace(
                  ":verificationToken",
                  (e.context as VerificationRequiredContext).token,
                ),
                true,
              );
              break;
            case "EmailConfirmationRequired":
              setLocationHref(confirmLink, true);
              break;
            default:
              setErrorMessages(["signIn.form.error"]);
              break;
          }
        } else {
          setErrorMessages(["signIn.form.error"]);
        }
      }
    },
    [captcha, confirmLink, passwordResetRequiredLink, signInVerificationLink],
  );

  const handleSignOut = useCallback(() => {
    signOut().finally(reloadLocation);
  }, []);

  if (currentUser) {
    return (
      <AlreadySignedIn
        username={currentUser.username}
        continueLink={continueLink}
        handleSignOut={handleSignOut}
      />
    );
  }

  return (
    <SignInForm
      languageCode={languageCode}
      params={params}
      signUpLink={signUpLink}
      forgotUsernameLink={forgotUsernameLink}
      forgotPasswordLink={forgotPasswordLink}
      errorMessages={errorMessages}
      recaptchaV2SiteKey={recaptchaV2SiteKey}
      onSubmit={handleSubmit}
      showTwoFactor={showTwoFactor}
      showCaptcha={showCaptcha}
    />
  );
};

export default Route;
