import React from "react";
import styled from "styled-components";

import { PrimaryButtonLink, DefaultButton } from "@envato/eds";
import { BodyText } from "@envato/sso-common";

import SingleColumn from "../../../layouts/SingleColumn";

export interface ViewProps {
  username: string;
  continueLink: string;
  handleSignOut: () => void;
}

const UsernameText = styled(BodyText)`
  display: inline;
  font-weight: ${(props) => props.theme.fontWeightBulky};
`;

const View: React.FC<ViewProps> = ({
  username,
  continueLink,
  handleSignOut,
}) => {
  return (
    <SingleColumn
      title="signIn.alreadySignedIn.title"
      testId="alreadySignedInTitle"
      size="small"
    >
      <BodyText
        text="signIn.alreadySignedIn.message"
        values={{
          currentUser: <UsernameText>{username}</UsernameText>,
        }}
      />
      <PrimaryButtonLink
        fullWidth
        text="signIn.alreadySignedIn.continue"
        to={continueLink}
        values={{
          currentUser: username,
        }}
        testId="continueButton"
      />
      <DefaultButton
        text="signIn.alreadySignedIn.signOut"
        fullWidth
        onClick={handleSignOut}
        testId="signOutButton"
      />
    </SingleColumn>
  );
};

export default View;
