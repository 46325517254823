import React from "react";

import { ClientParams, useClient } from "@envato/sso-common";

import Default from "./Default";
import ElementsDefault from "./ElementsDefault";

export interface SidePanelProps {
  params: ClientParams;
  signUpLink: string;
}

const templateFor = (
  context: string = "default",
  client?: string,
): React.FC<SidePanelProps> => {
  const key = [context, client].join("_");

  switch (key) {
    case "default_elements":
    case "default_extensions-backend":
      return ElementsDefault;
    default:
      return Default;
  }
};

const SidePanel: React.FC<SidePanelProps> = ({ params, signUpLink }) => {
  const client = useClient();

  const SidePanelComponent = templateFor(params.context, client?.appId);

  return <SidePanelComponent params={params} signUpLink={signUpLink} />;
};

export default SidePanel;
