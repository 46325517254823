import React from "react";

import { Stack } from "@envato/eds";

import { Container, Title, BodyText } from "../../../../components/SidePanel";

import { SidePanelProps } from "./index";

const SidePanel: React.FC<SidePanelProps> = () => {
  return (
    <Container>
      <Stack spacing="4x">
        <Title text="signIn.form.title.elements" />
        <BodyText text="signIn.form.subtitle.elements" />
      </Stack>
    </Container>
  );
};

export default SidePanel;
